import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Typography is defined as the science of style, appearance, and structure of fonts that aim to provide convenience and aesthetics to the reader. Effective typography depends not only on the content but also on the presentation visual of the typography itself, starting from the font type, font size and line height. You can chek out the guideline for more detail description.`}</p>
    <div className="divi" />
    <p>{`In your composable function, can use value LegionTheme.typography.{typography_token} on the colors attribute widget. Example below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourComposable() {
    Text(
        text = stringResource(id = R.string.your_string),
        style = LegionTheme.typography.bodyLargeRegular,
        color = LegionTheme.colors.tertiary700
    )
}

`}</code></pre>
    <h2>{`Heading`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Hero section, Title content or component, Subtitle of title`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Line Height`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading1'>{`Heading 1`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading2'>{`Heading 2`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading3'>{`Heading 3`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading4'>{`Heading 4`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading5'>{`Heading 5`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileHeading6'>{`Heading 6`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26dp`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Heading 1`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val Heading = TextStyle(
        fontSize = 34.sp,
        lineHeight = 48.sp,
        fontWeight = FontWeight.Bold,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val Heading1 = Heading.copy(fontSize = 34.sp, lineHeight = 48.sp)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        heading1 = Heading1
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h3>{`Heading 2`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val Heading = TextStyle(
        fontSize = 34.sp,
        lineHeight = 48.sp,
        fontWeight = FontWeight.Bold,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val Heading2 = Heading.copy(fontSize = 28.sp, lineHeight = 42.sp)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        heading2 = Heading2
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h3>{`Heading 3`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val Heading = TextStyle(
        fontSize = 34.sp,
        lineHeight = 48.sp,
        fontWeight = FontWeight.Bold,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val Heading3 = Heading.copy(fontSize = 24.sp, lineHeight = 36.sp)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        heading3 = Heading3
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h3>{`Heading 4`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val Heading = TextStyle(
        fontSize = 34.sp,
        lineHeight = 48.sp,
        fontWeight = FontWeight.Bold,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val Heading4 = Heading.copy(fontSize = 22.sp, lineHeight = 32.sp)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        heading4 = Heading4
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h3>{`Heading 5`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val Heading = TextStyle(
        fontSize = 34.sp,
        lineHeight = 48.sp,
        fontWeight = FontWeight.Bold,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val Heading5 = Heading.copy(fontSize = 20.sp, lineHeight = 28.sp)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        heading5 = Heading5
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h3>{`Heading 6`}</h3>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val Heading = TextStyle(
        fontSize = 34.sp,
        lineHeight = 48.sp,
        fontWeight = FontWeight.Bold,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val Heading6 = Heading.copy(fontSize = 18.sp, lineHeight = 26.sp)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        heading6 = Heading6
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Body`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Description, Paragraph, Button, Text Field, Tabs, etc`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeReguler'>{`Large Reguler`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodyLargeRegular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeSemibold'>{`Large Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodyLargeSemiBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeBold'>{`Large Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodyLargeBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileLargeItalic'>{`Large Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodyLargeItalic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18sp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallReguler'>{`Small Reguler`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodySmallRegular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14sp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallSemibold'>{`Small Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodySmallSemiBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14sp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallBold'>{`Small Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodySmallBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14sp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileSmallItalic'>{`Small Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bodySmallItalic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14sp`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Body Large`}</h3>
    <h4>{`Body Large Regular`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val BodyLarge = TextStyle(
        fontSize = 18.sp,
        lineHeight = 24.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        bodyLargeRegular = BodyLarge
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Body Large Semi Bold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val BodyLarge = TextStyle(
        fontSize = 18.sp,
        lineHeight = 24.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val BodyLargeSemiBold = BodyLarge.copy(fontWeight = FontWeight.SemiBold)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        bodyLargeSemiBold = BodyLargeSemiBold
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Body Large Bold`}</h4>
    <p>{`Create your typography styles :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val BodyLarge = TextStyle(
        fontSize = 18.sp,
        lineHeight = 24.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)
    
val BodyLargeBold = BodyLarge.copy(fontWeight = FontWeight.Bold)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        bodyLargeBold = BodyLargeBold
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Body Large Italic`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val BodyLarge = TextStyle(
        fontSize = 18.sp,
        lineHeight = 24.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val BodyLargeItalic = BodyLarge.copy(fontStyle = FontStyle.Italic)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        bodyLargeItalic = BodyLargeItalic
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h3>{`Body Small`}</h3>
    <h4>{`Body Small Regular`}</h4>
    <p>{`Create your own typography styles :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val BodySmall = TextStyle(
        fontSize = 14.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        bodySmallRegular = BodySmall
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Body Small Semi Bold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val BodySmall = TextStyle(
        fontSize = 14.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val BodySmallSemiBold = BodySmall.copy(fontWeight = FontWeight.SemiBold)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        bodySmallSemiBold = BodySmallSemiBold
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Body Small Bold`}</h4>
    <p>{`Create your typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val BodySmall = TextStyle(
        fontSize = 14.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)
    
val BodySmallBold = BodySmall.copy(fontWeight = FontWeight.Bold)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        bodySmallBold = BodySmallBold
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Body Small Italic`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val BodySmall = TextStyle(
        fontSize = 14.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val BodySmallItalic = BodySmall.copy(fontStyle = FontStyle.Italic)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        bodySmallItalic = BodySmallItalic
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Caption`}</h2>
    <p><strong parentName="p">{`Common usage:`}</strong>{` Description, Tags, Chips, Badge, etc`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Line Height`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionLargeReguler'>{`Large Reguler`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionLargeRegular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionLargeSemibold'>{`Large Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionLargeSemiBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionLargeBold'>{`Large Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionLargeBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionLargeItalic'>{`Large Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionLargeItalic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallReguler'>{`Small Reguler`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionSmallRegular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallSemibold'>{`Small Semibold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionSmallSemiBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallBold'>{`Small Bold`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionSmallBold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='mobileCaptionSmallItalic'>{`Small Italic`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`captionSmallItalic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18dp`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Caption Large`}</h3>
    <h4>{`Caption Large Regular`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val CaptionLarge = TextStyle(
        fontSize = 12.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val CaptionLargeRegular = CaptionLarge
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        captionLargeRegular = CaptionLarge
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Caption Large SemiBold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val CaptionLarge = TextStyle(
        fontSize = 12.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val CaptionLargeSemiBold = CaptionLarge.copy(fontWeight = FontWeight.SemiBold)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        captionLargeSemiBold = CaptionLargeSemiBold
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Caption Large Bold`}</h4>
    <p>{`Create your typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val CaptionLarge = TextStyle(
        fontSize = 12.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val CaptionLargeBold = CaptionLarge.copy(fontWeight = FontWeight.Bold)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        captionLargeBold = CaptionLargeBold
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Caption Large Italic`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val CaptionLarge = TextStyle(
        fontSize = 12.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val CaptionLargeItalic = CaptionLarge.copy(fontStyle = FontStyle.Italic)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        captionLargeItalic = CaptionLargeItalic
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h3>{`Caption Small`}</h3>
    <h4>{`Caption Small Regular`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val CaptionSmall = TextStyle(
        fontSize = 10.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val CaptionSmallRegular = CaptionSmall
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        captionSmallRegular = CaptionSmall
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Caption Small SemiBold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val CaptionSmall = TextStyle(
        fontSize = 10.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val CaptionSmallSemiBold = CaptionSmall.copy(fontWeight = FontWeight.SemiBold)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        captionSmallSemiBold = CaptionSmallSemiBold
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Caption Small Bold`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val CaptionSmall = TextStyle(
        fontSize = 10.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val CaptionSmallBold = CaptionSmall.copy(fontWeight = FontWeight.Bold)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        captionSmallBold = CaptionSmallBold
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>
    <h4>{`Caption Small Italic`}</h4>
    <p>{`Create your own typography styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`val CaptionSmall = TextStyle(
        fontSize = 10.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Normal,
        fontStyle = FontStyle.Normal,
        fontFamily = NunitoSans
)

val CaptionSmallItalic = CaptionSmall.copy(fontStyle = FontStyle.Italic)
`}</code></pre>
    <p>{`Register in your themes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`LegionAppTheme(
    ...
    typography = LgnTypography(
        captionSmallItalic = CaptionSmallItalic
    )
    ...
    darkTheme = false,
    content = content
)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      